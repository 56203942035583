html, 
body,
#root {
    height: 100%;
}

body {
  margin: 0;
  background: #eee;

  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.logo {
  max-width: 200px;
}

.center {
  text-align: center;
}

i {
  font-style: unset;
}

.m-0 {
  margin: 0 !important;
}
.w-100 {
  width: 100%;
}

.p-5 {
  padding: 5px;
}

.fs-25 {
  font-size: 25px;
}

.red {
  color: red;
}

.orange {
  color: orange;
}

.green {
  color: green;
}

.inline-block {
  display: inline-block !important;
}

.columns {
  display: flex;
  flex-direction: column;
}