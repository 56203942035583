.dashboard {
    width: 100%;
    height: 100%;
    display: flex;

    .left {
        width: 50%;
        padding: 10px;

        .form-wrapper {
            margin-bottom: 10px;

            .filters-container {
                display: flex;
                flex-direction: column;

                .filters-wrapper {
                    margin: 5px 0 5px 0;
                }
            }
        }
    }
    
    .right {
        width: 50%;
        padding: 10px;
    }
}
.MuiTabs-scroller {
    overflow-x: scroll !important;
}

@media (max-width: 640px) {
    .dashboard {
        flex-direction: column;
        padding: 10px;

        .left {
            width: 100%;
            padding: 0;
        }
        
        .right {
            width: 100%;
            padding: 0;
            margin: 10px 0 10px 0;
        }

        .empty-right {
            display: none;
        }
    }
}

.empty-right {
    position: absolute;
    left: 75%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}