.domain, address, ssh {
    word-break: break-word;
}

.table-border {
    td {
        border: 1px solid #333;
    }
    
    thead,
    tfoot {
        background-color: #333;
        color: #fff;
    }
}