.auth {
    width: 400px;
    margin: auto;
    text-align: center;

    .auth-wrapper {
        margin-top: 20%;

        .login-title {
            font-weight: inherit;
        }

        form {
            height: 200px;
            display: flex;
            justify-content: space-around;
            flex-direction: column;
        }
    }
}